import * as React from "react";

const FAQ = () => {

    const faqs = [
        {
            id: 1,
            question: "Am I locked in if I choose HealthOne Digital?",
            answer: "You own your website and are not locked in to any contract other than a service contract. You can transfer your website at any time.",
        },
        {
            id: 2,
            question:"Do you provide hosting services?",
            answer:"Yes we provide hosting services if a client chooses to host with us. It's included in our update and maintenance plans.",
        },
        {
            id: 3,
            question:"Can I edit my website by myself?",
            answer:"Yes you can add/remove/edit any content in the Content Management System which includes Services, Team Members, Job Openeings etc. You will not be able to edit the design of the website.",
        },
        {
            id: 4,
            question:"What if I want to make changes to the design of my website?",
            answer:"It's as easy as sending us an email with the changes you would like.",
        },
        {
            id: 5,
            question:"What if I already have an existing website?",
            answer:"If you already have an existing website, we can help you move all the content over to your new website.",
        },
        {
            id: 6,
            question:"How much do you charge for a website?",
            answer:"As every project is unique, we can give you an esitmate once you reach out to us.",
        },
        {
            id: 7,
            question:"",
            answer:"",
        },
    ]

    return(
        <section>
        <div className="bg-white">
            <div className="mx-auto max-w-7xl divide-y divide-gray-900/10 px-6 py-24 lg:px-8">
                <h2 className="text-2xl font-bold leading-10 tracking-tight text-blue-900">Frequently Asked Questions</h2>
                <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
                {faqs.map((faq) => (
                    <div key={faq.id} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                        <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">{faq.question}</dt>
                        <dd className="mt-4 lg:col-span-7 lg:mt-0">
                        <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                        </dd>
                    </div>
                 ))}
                </dl>
            </div>
      </div>
      </section>
    )

}

export default FAQ;
